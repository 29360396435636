.autoCompleteInput{
  font-size: 15px;
  font-family: Arial;
  color: rgb(51, 51, 51); 
  width: 100%;
  outline: none;
  /* margin: 0px;  */
  /* padding: 0px; */
  vertical-align: top;
  position: relative;
  border: none;
}

.ui.form input.reduceHeight{
  padding: 0.23em 0.7em;
  line-height: 1.6em;
}

.ui.form input.disabled.autoCompleteInput.greyDisabled{
  background-color: #E0E0E0;
  opacity: 1;
}

.autoComplete{
  /* border: 0.1px solid #ddd; */
  width: 100%;
  outline: none;
}

.autoCompleteMenu{
  position: relative;
  outline: 0px;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.ui.menu.autoCompleteItem{
  display: block;
  position: absolute;
  width: 100%;
  outline: 0px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  font-size: 15px;
  font-family: Arial;
  z-index: 100;
  cursor: default;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(170, 170, 170) ;
  overflow: hidden scroll;
  white-space: pre;
  left: 0px; 
  top: 100%; 
  max-height: 209.75px;
}

.ui.menu.autoCompleteItem.hidden{
  display: none;
}