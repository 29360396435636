$primary-color: var(--primary-theme-color);
$header-height: 40px;

.body-tf-transform {
  .ui.modal.transition.visible.active {
    border-radius: 15px;
    overflow: hidden;
    width: 80%;
    height: auto;
    max-width: 24rem;
    .ui.inverted.segment.modHeader,
    .header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      text-align: center;
    }
    .ui.error.visible.message {
      margin: 0 0 5px;
      max-height: 50vh;
      overflow: auto;
    }
    .modContent {
      padding-top: 1em;
    }
    .ui.button {
      margin-top: 0 !important;
      height: 40.2px;
      border-radius: 10px;
      min-width: 40%;
      &.approveButton {
        margin-top: 15px !important;
      }
    }
  }
}
.tf-transform-chat {
  .list.large.icon {
    font-size: 1em;
    margin-left: 20px;
  }
}

.tf-transform {
  ::-webkit-scrollbar {
    display: none;
  }

  background-color: white;
  *:not(input, textarea) {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }

  // Loader
  .ui.loader {
    color: transparent !important;
    &::after,
    &::before {
      width: 2.65rem !important;
      height: 2.65rem !important;
    }
    &::after {
      border-color: $primary-color $primary-color transparent !important;
      border-left: 0 !important;
    }
  }
  // profile
  // .tf-profile {

  .MuiPaper-root.MuiStepper-root {
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      margin-top: 10px;
    }
    .MuiStep-root {
      & > div[class*="active"],
      & > div[class*="completed"] {
        span {
          border-color: $primary-color;
        }
      }
    }
    .MuiStepIcon-root {
      &.MuiStepIcon-completed,
      &.MuiStepIcon-active {
        color: $primary-color;
      }
    }
  }
  .tf-profile {
    .ui.inverted.dimmer {
      position: fixed;
      margin-top: $header-height;
      height: calc(100vh - #{$header-height});
    }
    .profile-create.wizard.profile-title {
      margin-top: 20px;
    }
  }
  .profile {
    background-color: white;

    .DayPickerInput-OverlayWrapper {
      select {
        height: 23px;
      }
      .DayPicker-Day,
      .DayPicker-WeekNumber {
        padding: 0.5em 0.65em;
      }
    }

    .ui.button,
    .MuiButton-root {
      border-radius: 10px;
      min-height: 40.2px;
      line-height: 18px;
    }
    .ui.input input,
    textarea {
      border-radius: 8px;
    }
    .logout-div .ui.button {
      width: 85%;
    }
    .allergy-checkbox > div {
      margin-bottom: 12px !important;
    }
  }
  // }

  // Diag rule search
  .diag-rule-search {
    height: calc(100vh - #{$header-height});
    overflow-y: auto;
    background-color: white;
    .ui.fluid.left.icon.input {
      padding: 5px;
      input {
        border-radius: 8px;
      }
    }
    .ui.celled.list {
      & > .item:first-child {
        .wide.column {
          width: 50% !important;
          p {
            color: grey;
          }
        }
      }
      & > .item,
      & > .list {
        border: 0;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      }
    }

    .item.diag-rule-list-item {
      padding: 17.5px 10px;

      &:first-child,
      &:last-child {
        padding: 17.5px 10px;
      }

      .ui.tiny.label {
        line-height: 14px;
      }
      .five.wide.column {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .ui.celled.selection.middle.aligned.list {
      margin: 0;
      padding: 0 10px 25px;
    }
  }

  // Diag rule search
  .official-account {
    height: calc(100vh - #{$header-height});
    overflow-y: auto;
    background-color: white;
    .ui.fluid.left.icon.input {
      padding: 5px;
      input {
        border-radius: 8px;
      }
    }
  }

  // Form
  .App-main {
    background-color: white;
    height: calc(100vh - 0px);
    overflow-y: auto;
    .App-content {
      .dimmable {
        .ui.label {
          padding: 15px;
          font-size: 1rem;
          margin: 10px 5px 15px;
        }
        .ui.button {
          border-radius: 5px;
        }
        .ui.input input {
          border-radius: 8px;
        }
        .ui.checkbox {
          margin-bottom: 15px !important;
        }
      }
    }
  }

  .chat-page,
  .patient-payment {
    .ui.inverted.dimmer {
      position: fixed;
      top: $header-height !important;
      height: calc(100vh - #{$header-height});
    }
  }

  // Appointment
  .TimePatient {
    .dimmable {
      height: calc(100vh - #{$header-height} - 50px);
      overflow-y: auto;
      padding: 0 10px 18px;
      background-color: white;

      h1 {
        font-size: 1.3rem;
      }
      h2 {
        font-size: 1.225rem;
      }
      .appointCard {
        padding: 15px 10px;
        .ui.button {
          border-radius: 5px;
        }
      }
    }
    .ui.inverted.dimmer {
      position: fixed;
      margin-top: $header-height;
      height: calc(100vh - #{$header-height} - 30px);
    }
    .no-appoint {
      height: auto;
      margin-top: calc(50vh - #{$header-height} - 30px);
    }
  }

  // Patient select appointment
  .patient-select-appointment {
    height: calc(100vh - #{$header-height});
    overflow-y: auto;
    padding: 15px;
    background-color: white;
    h2 {
      font-size: 1.5rem;
    }
    .ui.form .inline.fields .wide.field {
      padding-bottom: 5px;
      padding-right: 5px;
    }
    .ui.dropdown {
      border-radius: 8px;
    }
    .button-container .ui.button {
      min-height: 40.2px;
      border-radius: 5px;
      background-color: $primary-color;
    }
  }

  // payment
  .patient-payment {
    height: calc(100vh);
    overflow-y: auto;
    padding-bottom: 16px;
    .dimmable {
      position: absolute;
      height: calc(100vh);
      overflow-y: auto;
      top: 0;
      width: 100%;
    }
    .ui.secondary.pointing.menu {
      position: fixed;
      top: $header-height;
      width: 100%;
      .item {
        padding: 1em 1.14285714em;
      }
    }

    & > div:nth-child(2) {
      & > div:nth-child(2) {
        margin-top: 54px !important;
      }
    }

    .sub-patient-payment {
      padding: 10px 15px;
      .pay-btn .ui.button {
        border-radius: 5px;
        min-height: 35.2px;
        margin: 0;
      }
    }
  }

  // Payment detail
  .patient-payment-detail {
    .dimmable {
      height: calc(100vh - #{$header-height});
    }
    .pay-btn.ui.button {
      min-height: 45.2px;
      font-size: 1.3rem;
    }
  }

  // Address
  .address-confirm {
    height: calc(100vh - 0px);
    overflow-y: auto;
    .ui.inverted.dimmer {
      position: fixed;
      margin-top: $header-height;
      .ui.loader {
        margin-top: -#{$header-height};
      }
    }
    .content .ui.segment {
      border-radius: 8px;
    }
    .btn-group button {
      min-height: 40.2px;
    }
  }

  // Select-address
  .select-address {
    height: calc(100vh - 0px);
    overflow-y: auto;
    .dimmable {
      height: calc(100vh - #{$header-height} - 50px);
    }
    .add-new-address {
      padding: 5px 0;
    }
  }

  .tf-header i {
    color: white;
    border-radius: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1px;
    padding: 18px;
    margin: 0;
    &:active {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  // edit-address
  .edit-address {
    height: calc(100vh - 0px);
    overflow-y: auto;
    .ui.inverted.dimmer {
      position: fixed;
      margin-top: $header-height;
      .ui.loader {
        margin-top: -#{$header-height};
      }
    }
    .address-detail {
      padding: 5px 10px 5px 20px;
      border-radius: 8px;
      margin: 0 15px 10px;
    }
    .btn-group {
      position: relative;
      margin-top: 25px;
      .ui.button {
        min-height: 40.2px;
        margin: 0;
      }
    }
  }

  // Checkbox
  .ui.checkbox {
    &:not(.fitted) {
      display: flex;
      align-items: center;
    }
    label {
      padding-left: 2.2em;
      &::before {
        border-width: 2px;
      }
      &::after {
        // background-color: #90419a;
        color: $primary-color !important;
      }
      &::before,
      &::after {
        width: 20px;
        height: 20px;
        // font-size: 15px;
        padding-top: 1px;
      }
    }

    &.checked {
      label:before {
        border-color: $primary-color !important;
      }
      &.radio {
        label::after {
          background-color: $primary-color !important;
        }
      }
    }
  }
}

// Register

.tf-register {
  background-color: white;
  height: 100vh;
  .slider.animated {
    width: 100vw;
  }
  // .carousel .slider-wrapper {
  //   overflow-x: hidden;
  //   overflow-y: auto;
  // }

  .button-submit-primary {
    background: var(--primary-theme-color) !important;
    color: white !important;
    font-size: 16px;
    padding: 15px 0;
    border-radius: 5px;
  }
  .carousel-root {
    height: calc(100vh - #{$header-height});
    overflow: auto;
    .carousel .control-dots {
      .dot {
        width: 10px;
        height: 10px;
        background: #c4c4c4;
        opacity: 1;
        box-shadow: none;
        margin: 0 5px;
        pointer-events: none;
        &.selected {
          background: #5b6d9f;
        }
      }
    }
    .carousel.carousel-slider {
      padding-bottom: 7vh;
      width: 100vw !important;
    }
  }

  .ui.icon.input {
    input {
      color: #5b6d9f;
      font-size: 16px;
    }
    i {
      color: $primary-color;
    }
  }
  .ui.input > input:focus {
    border-color: $primary-color;
  }
  .label-required::after {
    content: "*";
    color: red;
  }

  &.ui.modal.transition.visible.active {
    border-radius: 15px;
  }
  &.tf-main,
  .tf-main {
    padding: 15px 30px;
    width: 100%;
    .DayPickerInput-OverlayWrapper {
      select {
        height: 23px;
      }
      .DayPicker-Day,
      .DayPicker-WeekNumber {
        padding: 0.5em 0.65em;
      }
    }
  }

  @media (max-height: 675px) {
    .box-logo-login {
      padding: 0 !important;
    }
  }
}

// Bottom navigation
.box-navigation {
  position: fixed;
  // bottom: 0;
  height: 54px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  z-index: 1000;
  background-color: white;
  padding-bottom: 8px;

  & > div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #bdbdbd;
    font-weight: bold;
    font-size: 0.8rem;

    i {
      margin-bottom: 20px;
      font-size: 16px;
    }

    img {
      margin-bottom: 16px;
      width: 20px;
    }

    .icon-svg {
      margin-bottom: 16px;
      width: 20px;
    }

    .icon-svg path {
      stroke: #bdbdbd;
     }

    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: top 0.3s ease, width 0.3s ease, height 0.3s ease;
      width: 15px;
      height: 15px;
      top: 15px;
    }

    div:nth-child(2) {
      position: absolute;
      top: 29px;
    }

    &.active {
      div:nth-child(2),
      i {
        color: $primary-color;
      }

    }

    &.icon-svg {
      div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: top 0.3s ease, width 0.3s ease, height 0.3s ease;
        width: 20px;
        height: 15px;
        top: 15px;

        img,
        .iconsvg {
          margin-bottom: 15px;
          width: 20px;
        }
    
        .iconsvg path {
          stroke: #797878;
         }
      }
      &.active {
        div:nth-child(1) {
          .iconsvg path {
            stroke: $primary-color;
           }
        }
        div:nth-child(2) {
          color: $primary-color;
        }
      }
    }

    &.main-menu {
      div:nth-child(1) {
        background-color: white;
        position: absolute;
        border-radius: 50rem;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -16.5px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

        img,
        i {
          width: 29px;
          margin: 0;
          font-size: 22px;
        }
      }
      &.active {
        div:nth-child(1) {
          background-color: $primary-color;
          img {
            filter: brightness(0) invert(1);
          }
        }
        div:nth-child(2) {
          color: $primary-color;
        }
      }
    }
  }
}

// Chat list
.tf-chat-list {
  background-color: white;
  .chat-page {
    .dimmable {
      height: calc(100vh - #{$header-height});
      overflow-y: auto;
    }
    .chat-list-search.menu {
      padding: 5px !important;
      input {
        border-radius: 8px;
      }
    }

    .ui.celled.selection.middle.aligned.list {
      margin: 0;
      padding-bottom: 67.5px;
    }

    .ui.celled.list > .item,
    .ui.celled.list > .list {
      border: 0;
      position: relative;
      padding: 25px;
      display: flex;
      align-items: center;

      div:nth-child(2) {
        width: 87.5%;
        padding: 0 0em 0 1.55em;
        margin: 0;
      }

      div:nth-child(3) {
        margin: 0;
        position: absolute;
        right: 15px;
        top: 10px;
      }

      img {
        //content: url("https://upload.wikimedia.org/wikipedia/th/b/b5/TUHospital.jpeg");
        width: 34px !important;
        height: 34px !important;
      }

      &::before {
        content: "";
        width: 52px;
        height: 52px;
        position: absolute;
        top: calc(50% - 26px);
        left: 16px;
        border-radius: 50rem;
        box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
      }

      &::after {
        content: "";
        width: 90%;
        visibility: unset;
        display: flex;
        margin-left: 5%;
        background: rgba(189, 189, 189, 0.5);
        position: absolute;
        bottom: 0;
        height: 1px;
        margin-left: -2.5%;
      }
    }
    .no-user-id {
      margin: 0;
      height: calc(72vh);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// Chat channel
.tf-chat-box {
  .ChatBox {
    height: calc(100vh - #{$header-height});
    // background-color: #f2e8f3;
    .textBox {
      // position: fixed;
      width: 100%;
      bottom: 0;
      // textarea {
      //   border: 1px solid $primary-color;
      // }
    }
    .messageView {
      padding: 0 15px 0 8px;
      margin-top: 0;
      // height: calc(100vh - 110px);
      .chat-loader {
        padding-bottom: 25px;
      }
    }
    .menu-div {
      padding: 0 5px;
      background-color: var(--primary-font-color);
    }
  }

  .FriendMessage {
    .field.friendName {
      padding-bottom: 3px;
    }
    .inline.fields .field.content {
      border-radius: 1.5em;
      padding: 0.8rem 1.2rem;
      border-top-left-radius: 0;
    }
  }

  .MyMessage .inline.fields .field.content {
    border-radius: 1.5em;
    padding: 0.8rem 1.2rem;
    border-top-right-radius: 0;
    // background-color: rgb(217, 117, 217);
  }
}

// Slide route
.slide-routes.slide {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: white;

  .floor {
    &.t-0 {
      transform: translateX(0%);
      transition: none;
    }
    &.t-100 {
      transform: translateX(-20%);
      transition: none;
    }
  }
  .ceil {
    &.t-0 {
      transform: translateX(0%);
      transition: none;
    }
    &.t-100 {
      transform: translateX(100%);
      transition: none;
    }
  }

  .position {
    position: absolute;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    box-shadow: 0 0 8px 1px rgb(133 133 133 / 30%);
    background-color: white;

    &::before {
      content: "";
      background: rgba(0, 0, 0, 0.025);
      width: 200vw;
      height: 100vh;
      position: absolute;
      z-index: -1;
      margin-left: -100vw;
    }
  }
}

.tf-mod-info {
  border-radius: 20px;
  width: 80%;
  & > div:nth-child(1) {
    padding: 35px 20px 30px;
    .mod-header {
      font-size: 1.4375rem;
      color: var(--primary-theme-color);
      font-weight: bold;
      text-align: center;
      @media (max-width: 345px) {
        font-size: 1.375rem;
      }
    }
    .box-modal-detail {
      font-size: 1.125rem;
      text-align: center;
      line-height: 26px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 155px;
      font-weight: bold;
      color: #333333;
    }
  }
}
