.App-tab {
  background-color: grey;
  color: white;
  font-size: calc(10px + 2vmin);  
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 40px;
}

.App-tabitem {
  text-align: center;
  flex-grow: 1;
}

a.App-tabitem:link { color: white; text-decoration: none;}
a.App-tabitem:visited { color: white; text-decoration: none;}
a.App-tabitem:hover { color: white; text-decoration: none;}
a.App-tabitem:active { color: white; text-decoration: none;}