/* Common.css react-lib */

.textButton {
  color: #4183c4;
}

.reduceHeight .ui.table .ui.input input,
.reduceHeight .ui.form .field .ui.input input,
.reduceHeight .ui.form .fields .field .ui.input input,
.reduceHeight .ui .input input[type="text"],
.reduceHeight input[type="email"],
.reduceHeight input[type="search"],
.reduceHeight input[type="password"] {
  /* reduce height of input */
  padding: 0.23em 0.7em;
  line-height: 1.6em;
}

.reactTablePadding {
  /* padding that have equal size to Cell padding in ReactTable */
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 5px;
}

.ui.dropdown.fluidDropdown,
.ui.form .inline.fields .field > .selection.dropdown.fluidDropdown {
  width: 100%;
}

.ui.label.fluidLabel {
  width: 100%;
}

.ui.input.fluidInput {
  width: 100%;
}
.ui.input.fluidInput .react-time-picker__inputGroup__leadingZero {
  /* display: none; */
}

.ui.input.fluidInput .react-time-picker__inputGroup {
  margin: auto;
}

.ui.input.fluidInput .react-time-picker__inputGroup__divider {
  line-height: 1.8em;
}

.ui.button.blueHover:hover {
  background-color: #eef5fe;
}

.ui.button:not(.circular):not(.basic).noBoxShadow,
.ui.button:not(.circular):not(.basic).noBoxShadow:focus {
  /* must have !important bcause /static/css/common.css has !important */
  box-shadow: none !important;
}

.ui.disabled.noFaded.checkbox .box:after,
.ui.disabled.noFaded.checkbox label,
.ui.noFaded.checkbox input[disabled] ~ .box:after,
.ui.noFaded.checkbox input[disabled] ~ label {
  /* checkbox label when disabled */
  opacity: 1;
}

.ui.bottom.attached.segment .HideTab.tab {
  border: 0;
  padding: 0;
  background-color: transparent;
}

.ui.input.centerText input,
.ui.form .field .ui.input.centerText input,
.ui.form .fields .field .ui.input.centerText input,
.ui.active.modal.centerText {
  /* How to: className='centerText' */
  text-align: center;
}

.ui.multiple.search.dropdown.noMargin > input.search {
  margin: 0;
}

.ui.blueHover.menu > .item:hover,
.ui.blueHover.vertical.menu > .item:hover {
  /* use with semantic Tab */
  /* How to: <Tab menu={{ className: 'blueHover' }} /> */
  /* show light blue color when hover*/
  background-color: #eef5fe;
}

.ui.blueHover.menu > .active.item,
.ui.blueHover.vertical.menu > .active.item,
.ui.blueHover.menu > .active.item:hover,
.ui.blueHover.vertical.menu > .active.item:hover {
  /* use with semantic Tab */
  /* How to: <Tab menu={{ className: 'blueHover' }} /> */
  background-color: #56ccf2;
}

.ui.blueHoverBold.menu > .active.item,
.ui.blueHoverBold.vertical.menu > .active.item,
.ui.blueHoverBold.menu > .active.item:hover,
.ui.blueHoverBold.vertical.menu > .active.item:hover {
  /* use with semantic Tab */
  /* How to: <Tab menu={{ className: 'blueHover' }} /> */
  background-color: #56ccf2;
  font-weight: bold;
}

.ui.yellowHover.menu > .active.item,
.ui.yellowHover.vertical.menu > .active.item,
.ui.yellowHover.menu > .active.item:hover,
.ui.yellowHover.vertical.menu > .active.item:hover {
  /* use with semantic Tab */
  /* How to: <Tab menu={{ className: 'yellowHover' }} /> */
  background-color: #f2c94c;
}

.ui.yellowHover.menu > .item:hover,
.ui.yellowHover.vertical.menu > .item:hover {
  /* use with semantic Tab */
  /* How to: <Tab menu={{ className: 'yellowHover' }} /> */
  /* show light blue color when hover*/
  background-color: #f8e8b6;
}

.ui.noFaded.disabled.checkbox input,
.ui.form .inline.fields .field.noFaded,
.ui.form .inline.fields .field .noFaded {
  opacity: 1;
}

.blueTextHighlight,
.ui.form .inline.fields .field .blueTextHighlight {
  /* semantic Form blue text */
  /* How to: className='blueTextHighlight' */
  color: #2f80ed;
}

.ui.form .inline.fields .field .multiLineInLineForm {
  /* semantic Form.Group inline >> Form.Field  */
  /* How to: className='multiLineInLineForm' */
  white-space: pre-wrap;
}

.ui.table thead tr .tableHeader {
  /* semantic table header */
  /* How to: className='tableHeader' */
  font-weight: bold;
  background-color: #2f3e52;
  color: #ffffff;
  border: 1px solid #ddd;
}

.ui.celled.table tr .tableHeader {
  /* semantic Grid */
  /* How to: className='tableHeader' */
  font-weight: bold;
  background-color: #2f3e52;
  color: #ffffff;
  border: 1px solid #ddd;
}

.ui.form .inline.fields .field .noMargin,
.ui.form .inline.fields.noMargin {
  /* semantic Form.Group inline >> Form Field */
  /* How to: className='noMargin' */
  margin: 0;
}

.ui.form .field.noPadding,
.ui.form .inline.fields .field .noPadding,
.ui.form .inline.fields .wide.field.noPadding,
.ui.icon.input.noPadding,
.ui.table td.noPadding, 
.ui.table th.noPadding,
  /* semantic Form.Group inline >> Form Field */

.ui.grid>.column.noPadding,

.ui.attached:not(.message)+.ui.attached.segment:not(.top) {
  /* semantic Tab Pane */
  /* How to: className='noPadding' */
  padding: 0;
}

.ui.form .field.noPadding.--hard,
.ui.form .inline.fields .field .noPadding.--hard,
.ui.form .inline.fields .wide.field.noPadding.--hard {
  /* semantic Tab Pane */
  /* How to: className='noPadding' */
  padding: 0 !important;
}

.ui.form .field.hidden,
.ui.form .inline.fields .field .hidden,
.ui.form .inline.fields .wide.field.hidden {
  /* How to: className='hidden' */
  display: none;
}

.ui.form .inline.fields .field > .selection.dropdown.greyDropdown,
.ui.form .inline.field > .selection.dropdown.greyDropdown > .dropdown.icon,
.ui.form .inline.fields .field > .selection.dropdown.greyDropdown > .dropdown.icon {
  /* semantic grey dropdown */
  /* How to: className='greyDropdown' */
  background-color: #f2f2f2;
}

.ui.greyDisabled,
.ui.greyDisabled.disabled.input, 
.ui.greyDisabled.disabled.input input, 
.ui.greyDisabled.input:not(.disabled) input[disabled],
.ui textarea.greyDisabled:disabled,
/* input and textArea */

.ui.greyDisabled.disabled.search,
.ui.greyDisabled.disabled.selection.dropdown>.delete.icon, 
.ui.greyDisabled.disabled.selection.dropdown>.dropdown.icon, 
.ui.greyDisabled.disabled.selection.dropdown>.search.icon,
/* Dropdown and it's icon */

.ui.greyDisabled.disabled.dropdown, 
.ui.greyDisabled.dropdown .menu > .disabled.item,
.ui.form .greyDisabled.disabled.field, 
.ui.form .greyDisabled.disabled.fields .field,
/* Dropdown and it's icon */

.ui.checkbox.greyDisabled label:before {
  background-color: #e0e0e0;
  opacity: 1;
}

.ui.yellowHighlight,
.ui.yellowHighlight.input, 
.ui.yellowHighlight.input input, 
/* input and textArea */

.ui.yellowHighlight.search,
.ui.yellowHighlight.selection.dropdown>.delete.icon, 
.ui.yellowHighlight.selection.dropdown>.dropdown.icon, 
.ui.yellowHighlight.selection.dropdown>.search.icon,
/* Dropdown and it's icon */

.ui.yellowHighlight.dropdown, 
.ui.form .yellowHighlight.field, 
.ui.form .yellowHighlight.fields .field,
/* Dropdown and it's icon */

.ui.checkbox.yellowHighlight label:before {
  background-color: #f2c94c;
  opacity: 1;
}

.ui.disabled.checkbox.noFaded.greyDisabled,
.ui.greyDisabled.disabled.selection.dropdown > .delete.icon,
.ui.greyDisabled.disabled.selection.dropdown > .dropdown.icon,
.ui.greyDisabled.disabled.selection.dropdown > .search.icon {
  background-color: transparent;
  opacity: 1;
}

.ui.form .field > .selection.dropdown.reduceHeight > .dropdown.icon,
.ui.form .inline.field > .selection.dropdown.reduceHeight > .dropdown.icon,
.ui.form .inline.fields .field > .selection.dropdown.reduceHeight > .dropdown.icon,
.ui.form .field > .selection.dropdown.reduceHeight > .dropdown.icon,
.reduceHeight i.dropdown.icon,
.reduceHeight .ui.form .field > .selection.dropdown > .dropdown.icon,
.reduceHeight .ui.form .inline.field > .selection.dropdown > .dropdown.icon,
.reduceHeight .ui.form .inline.fields .field > .selection.dropdown > .dropdown.icon,
.reduceHeight .ui.form .field > .selection.dropdown > .dropdown.icon {
  background-color: transparent;
}

.reduceHeight i.dropdown.icon,
.reduceHeight .ui.selection.dropdown,
.reduceHeight .ui.selection.dropdown>.dropdown.icon,
.ui.form .fields .field .ui.selection.dropdown.reduceHeight, 
.ui.form .field:not(.inline) .ui.selection.dropdown.reduceHeight, 
.ui.form .inline.fields .field>.selection.dropdown.reduceHeight
.ui.form .field>.selection.dropdown.reduceHeight>.dropdown.icon, 
.ui.form .inline.field>.selection.dropdown.reduceHeight>.dropdown.icon, 
.ui.form .inline.fields .field>.selection.dropdown.reduceHeight>.dropdown.icon,
.ui.form .field>.selection.dropdown.reduceHeight>.dropdown.icon,

.reduceHeight .ui.form .field>.selection.dropdown>.dropdown.icon, 
.reduceHeight .ui.form .inline.field>.selection.dropdown>.dropdown.icon, 
.reduceHeight .ui.form .inline.fields .field>.selection.dropdown>.dropdown.icon,
.reduceHeight .ui.form .field>.selection.dropdown>.dropdown.icon,
  /* reduce height of semantic dropdown, dropdown icon */
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child {
  /* reduce height of semantic input */
  padding: 0.5em;
}

.reduceLineSpace .ui .table,
.reduceLineSpace .ui.form .inline.fields {
  /* reduce line-space between each line */
  margin: 0 0 0.5em;
}

.ui.form .field > .selection.clearIconBg.dropdown > .dropdown.icon,
.ui.form .inline.field > .selection.clearIconBg.dropdown > .dropdown.icon,
.ui.form .inline.fields .field > .selection.clearIconBg.dropdown > .dropdown.icon,
.ui.selection.clearIconBg.dropdown > .delete.icon,
.ui.selection.clearIconBg.dropdown > .dropdown.icon,
.ui.selection.clearIconBg.dropdown > .search.icon {
  /* transparent background for semantic ui dropdown icon */
  /* use with compact dropdown */
  background-color: #ffffff00;
}

.hideArrow input[type="time"]::-webkit-inner-spin-button,
.hideArrow input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inline.fields.rowReverse,
.inline.fields .field.rowReverse {
  display: flex;
  flex-direction: row-reverse;
}

.noIcon input::-webkit-calendar-picker-indicator {
  display: none;
}

.ui.icon.input.noBorder,
.ui.form .inline.fields .field .noBorder {
  border: 0px solid;
}

.ui.input.priceInput input {
  text-align: right;
}

.ui.fluid.dropdown.margin-053 > .dropdown.icon {
  margin: -0.53333333em;
}

.rightAlign {
  justify-content: flex-end;
}

.ui.form .rightAlign.fields .field:last-child {
  padding: 0;
}

.ui.inverted.menu.bold .item {
  /* <Menu className='bold'> */
  /* bold Text in Menu.Item */
  font-weight: bold;
}

.ui.search.dropdown.block > .text {
  display: block;
}

.ui.modal.fixNotMiddleModal {
  margin: auto;
}

.ui.readOnly.dropdown,
.ui.dropdown .menu > .disabled.item {
  cursor: default;
  pointer-events: none;
}

.price {
  text-align: right;
}

.ui.transparentKeepPadding.input > input {
  border-color: transparent !important;
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ui.fluid.search {
  width: 100%;
}

/* for div table */
.table {
  display: table;
  border-spacing: 2px;
}
.caption {
  display: table-caption;
  text-align: center;
}
.colgroup {
  display: table-column-group;
}
.col {
  display: table-column;
}
.thead {
  display: table-header-group;
  vertical-align: middle;
}
.tbody {
  display: table-row-group;
  vertical-align: middle;
}
.tfoot {
  display: table-footer-group;
  vertical-align: middle;
}
.tr {
  display: table-row;
}
.th {
  display: table-cell;
  vertical-align: middle;
  padding: 1px;
  text-align: center;
  font-weight: 700;
}
.td {
  display: table-cell;
  vertical-align: middle;
  padding: 1px;
}

.table.fixed-header .th {
  position: sticky;
  top: 0px;
}

.ui.menu .item.disabled.disableDropDown {
  background-color: lightgrey !important;
  color: rgba(0, 0, 0, 0.95) !important;
}
