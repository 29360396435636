.App-plain {
  text-align: left;
  display: table;
  height: 100%;
  width: 100vw;
  /* grid-template-rows: 40px auto;  */
}

.App-header {
  background-color: #5dbcd2;
  text-align: left;
  color: white;
  font-size: calc(10px + 2vmin);
  line-height: 40px;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
  grid-template-columns: 50% auto;
}

.App-title {
  text-align: left;
  grid-column-start: 1;
  grid-column-end: 2;
  padding-left: 10px;
}

.App-menu {
  text-align: right;
  grid-column-start: 2;
  grid-column-end: 3;
  padding-right: 10px;
}

.App-main {
  font-size: calc(10px + 2vmin);  
  grid-row-start: 2;
  grid-row-end: 3;
  overflow-y: auto;
  text-align: left;
  display: grid;
}

.App-content {
  /* max-width: 1000px; */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.Feed-item  {
  display: grid;
  grid-template-columns: 40% auto;
}

.Feed-item-title {
  font-size: calc(10px + 2vmin);  
  text-align: left;
  padding: 10px;
  background-color: lightblue;
  grid-column-start: 1;
  grid-column-end: 2;
}

.Feed-item-content {
  font-size: calc(10px + 2vmin);  
  text-align: left;
  padding: 10px;
  grid-column-start: 2;
  grid-column-end: 3;
}

.App-with-tab {
  text-align: left;
  display: grid;
  height: 100%;
  grid-template-rows: 40px 40px auto;
}

.App-main-tab {
  font-size: calc(10px + 2vmin);  
  grid-row-start: 3;
  grid-row-end: 4;
  overflow-y: auto;
}

.Login {
  margin: auto
}

.Page {
  margin-top: 8px;
  margin-bottom: 8px; 
  margin-left: 16px; 
  margin-right: 16px;
}

.border {
  background: 
    linear-gradient(
      to bottom, 
      #FFFFFF, 
      #BDBDBD
    );
}