/* Tab */
.tab-custom {
    width: 100%;
    background-color: white;
    z-index: 1;
    &.dm {
      position: fixed;
    }
  
    .ui.pointing.secondary.menu {
      border-bottom: 0;
      margin-bottom: 2.5px;
      &:hover,
      .active.item {
        border-color: #5dbcd2;
        border-bottom-width: 3.5px;
        color: #5dbcd2;
      }
  
      .item {
        border-color: rgb(34 36 38 / 10%);
        border-bottom-width: 3px;
        color: #9e9e9e;
      }
    }
  }
  
  // Global
  .react-swipeable-view-container > .cheack-view {
    height: calc(100vh - 135px);
    overflow-x: hidden !important;
    height: calc(var(--dm-vh, 1vh) * 100 - 135px);
  }
  