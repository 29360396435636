.vc-appointment-slot {
  .filter-header {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 2;
    margin-top: -60px;
    padding: 15px;
    padding-right: 18px;
  }
  .slot-list {
    padding: 12px;
    padding-right: 18px;
    .title {
      font-size: 16px;
      text-align: center;
      font-weight: bold;

      label {
        color: rgb(0, 72, 132);
        font-size: 18px;
      }

      div {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgb(38, 124, 173);
        color: white;
        font-weight: bold;
        text-align: center;
        padding: 5px;
        margin-top: 3px;
      }
    }
    .slot-item {
      width: 100%;
      height: 39px;
      // background-color: red;
      border-radius: 5px;
      margin-bottom: 6px;
      display: grid;
      grid-template-columns: 110px auto;
      overflow: hidden;
      .time-label {
        background-color: #01b2ac;
        color: white;
        display: flex;
        padding-left: 10px;
        align-items: center;
      }
      .queue-progress {
        border: 0.5px solid #e0e0e0;
        div:nth-child(1) {
          background-color: #eeeeee;
          height: 100%;
          transition: all 2s ease 0s;
          width: 0%;
          // transition-delay: 5s;
        }
        .display-percent {
          position: absolute;
          right: 10px;
          top: 0px;
          font-size: 10px;
          display: flex;
          line-height: normal;
          flex-direction: column;
          align-items: flex-end;
          color: rgba(51, 51, 51, 0.8);
        }
      }
      &.booked {
        grid-template-columns: auto;
        .time-label {
          background-color: #f6b26b;
          justify-content: space-between;
          div:nth-child(2) {
            padding-right: 10px;
            display: flex;
            color: #004884;
            font-weight: bold;
          }
        }
        .queue-progress {
          display: none;
        }
      }
    }
  }
  .react-swipeable-view-container {
    width: 100vw;
  }
}
